import { Injectable } from '@angular/core';
import { ForgotPasswordApi } from '../apis/index.js';

@Injectable({ providedIn: 'root' })
export class ForgotPasswordGear {
  constructor(private forgotPasswordApi: ForgotPasswordApi) { }

  send(email: any) {
    return this.forgotPasswordApi.send(email);
  }

  sendActiveEmail(email: any) {
    return this.forgotPasswordApi.sendActiveEmail(email);
  }

  verifyActiveEmail(token: string, userId: string) {
    return this.forgotPasswordApi.verifyActiveEmail(token, userId);
  }

  check(token: any, userId: any) {
    return this.forgotPasswordApi.check(token, userId);
  }

  updateNewPassword(token: any, userId: any, password: any) {
    return this.forgotPasswordApi.updateNewPassword(token, userId, password);
  }
}
