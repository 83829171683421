import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ForgotPasswordApi {
  constructor(private apollo: Apollo, private httpClient: HttpClient) { }

  verifyActiveEmail(token: string, userId: string) {
    if (token && userId) {
      const url = `/v1/api/verify-active-email?token=${token}&userId=${userId}`;
      return this.httpClient.get(`${url}`);
    } else {
      return of();
    }
  }

  sendActiveEmail(email: string) {
    if (email && email.length > 0) {
      const url = `/v1/api/send-active-email?email=${email}&origin=${window.location.origin}`;
      return this.httpClient.get(`${url}`);
    } else {
      return of();
    }
  }

  send(email: string) {
    if (email.length > 0) {
      const url = `/v1/api/forgot-email?email=${email}&origin=${window.location.origin}`;
      return this.httpClient.get(`${url}`);
    } else {
      return of();
    }
  }

  updateNewPassword(token: any, userId: any, password: any) {
    const url = `/v1/api/new-password`;
    return this.httpClient.post(`${url}`, {
      token,
      userId,
      password,
    });
  }

  check(token: any, userId: any) {
    const url = `/v1/api/check-token`;
    return this.httpClient.post(`${url}`, { token, userId });
  }

}
